@media (max-width: 768px){
    /*unnecessary*/
}
@media (max-width: 481px){
    header div.content div.content-box h1{
        margin-bottom: 48px;
    }
    header div.content div.content-box{
        width: 320px;
    }
    .loader,
    div.field.submit .loader{
        right: 10px;
        top: -15px;
    }
    div.field.submit .submit.loader{
        right: 35px;
        top: -15px;
    }
    header div.content div.error p{
        font-size: 10px;
    }
    header div.content div.content-box div.input{
        width: 250px;
    }
    header div.content div.content-box div.input.phone input{
        width: 250px;
    }
    header div.content div.content-box div.input label{
        width: 100%;
    }
    header div.content div.content-box div.input input, header div.content div.content-box div.input.phone .select{
        width: 100%;
    }
    header div.content div.content-box div.results span,
    header div.content div.content-box div.input label,
    header div.content div.content-box div.input input,
    header div.content div.content-box div.input .select,
    header div.content div.content-box a#Verificar,
    header div.content div.content-box span.counter,
    div#termsmodal .modal-content a[data-dismiss="modal"],
    a#Verificar,
    input[type="submit"],
    a#Reservar{
        font-size: 18px;
    }
    header div.content div.error{
        width: 100%;
        margin-bottom: 100px;
    }
    header div.content div.error p{
        font-size: 14px;
    }
    header div.content div.content-box a#Verificar,
    div#termsmodal .modal-content a[data-dismiss="modal"],
    a#Verificar,
    input[type="submit"],
    a#Reservar{
        padding: 10px 30px;
    }
    header div.content div.content-box span.counter{
        height: 40px;
        width: 140px;
        line-height: 40px;
    }
    header div.content div.content-box div.terms{
        width: 280px;
        height: 40px;
        line-height: 40px;
    }
    header div.content div.content-box div.terms span a,
    header div.content div.content-box div.terms span p{
         font-size: 14px;
        margin-top: -5px;
    }
    header div.content div.content-box div.terms label{
        margin-top: 5px;
    }
    div.code-input{
        font-size: 25px;
        width: 50%;
        margin-left: 25%;
    }
    span.error {
        font-size: 15px;
    }
    div.modalTitle{
        font-size: 22px;
    }
    div.termsModalBody{
        font-size: 15px;
    }
    div.modalBody{
        font-size: 15px;
    }
    header div.content div.content-box div.results span button{
        font-size: 15px;
        bottom: -5px;
    }
    header div.content div.content-box h4{
        margin-top: -40px;
        margin-bottom: 40px;
        font-size: 15px;
    }
    button.question {
        margin-right: 6%;
    }
}
@media (max-width: 321px){
    header img.full{
      margin-top: 150px;
      width: 320px;
    }
    header div.content div.content-box{
        width: 300px;
    }
    header div.content div.content-box h1,
    div#termsmodal .modal-content h2{
        font-size: 16px;
        margin-bottom: 30px;
    }
    header div.content div.content-box input.without-label{
        width: 170px;
        font-size: 16px;
    }
    header div.content div.error {
        bottom: -130px;
        padding: 50px 0px 10px 0px;
    }
    header div.content div.error p{
        margin-left: 5%;
        margin-right: 5%;
        font-size: 12px;
    }
    header div.content ul li{
        font-size: 13px;
    }
    header div.content div.content-box div.input.phone input,
    header div.content div.content-box div.input label{
        font-size: 16px;
    }
    header div.content div.content-box div.results{
        margin-bottom: 30px;
    }
    header div.content div.content-box h4.countryName {
        margin-top: -30px;
        font-size: 14px;
    }
    header div.content div.content-box span button{
        right: -40px;
        font-size: 16px;
        top: -10px;
    }
    .loader,
    div.field.submit .loader{
        width: 20px;
        height: 20px;
        right: 20px;
    }
    div.field.submit .submit.loader{
        right: 30px;
        top: -15px;
    }
    div.code-input{
        font-size: 20px;
        width: 80%;
        margin-left: 10%;
    }
    span.error {
        font-size: 13px;
    }
    div.modalTitle {
        font-size: 100%;
    }
    .modalContent div.termsModalBody {
        font-size: 100%;
    }
    .modalButton.btn.btn-default.form {
        font-size: 120%;
        width: 50%;
    }
    button.question {
        font-size: 16px;
        margin-right: 11%;
    }
    [data-tooltip]:before {
        margin-bottom: -60%;
      }
    header div.counter p{
        font-size: 90%;
      }
}