.react-floating-label-input {
  width: 100%; }
  .react-floating-label-input * {
    font-family: inherit; }
  .react-floating-label-input .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 2em;
    border-bottom: 1px solid #ddd; }
  .react-floating-label-input input,
  .react-floating-label-input label {
    padding: 0;
    margin: 0;
    border: 0; }
  .react-floating-label-input input {
    border: none;
    outline: none;
    font-size: 1em; }
    .react-floating-label-input input::placeholder {
      color: #9b9b9b;
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .react-floating-label-input label {
    position: absolute;
    color: #9b9b9b;
    bottom: 0;
    transition: all linear 0.2s;
    transform-origin: left top;
    font-size: 1em;
    cursor: text;
    pointer-events: none;
    width: 66.6%; }
  .react-floating-label-input.active input::placeholder {
    opacity: 1; }
