$blue-20: #005566;
$blue-30: #008099;
$blue-40: #00abcc;

$primary-20: $blue-20;
$primary-30: $blue-30;
$primary-40: $blue-40;

$gray-80: #c7cfd1;

$white-100: #fff;
