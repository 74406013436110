label {
    text-align: left;
}

.inputField {
    margin-top: 20px;
}

.modalTitle {
    display: block;
}

.modalTitle:first-letter {
    text-transform: uppercase;
}

.divToScroll {
    background-color: #F5F5F5;
    border: 1px solid #DDDDDD;
    border-radius: 4px 0 4px 0;
    color: #3B3C3E;
    font-size: 12px;
    font-weight: bold;
    left: -1px;
    padding: 10px 7px 5px;
}

.divWithScroll {
    height: 420px;
    overflow: scroll;
    overflow-x: hidden;
}

.loader {
    margin-left: -50px;
    margin-top: 25px;
}

div.field.submit.user-form {
    margin: 50px 0px 20px 0px;
}

.modalContent {
    font-family: m-m;
}