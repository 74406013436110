@import "../../styles/colors";
@import "../../styles/measures";

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $xxxs $m;
  text-align: center;
  background-color: $gray-80;
  p {
    margin: 0;
    color: $white-100;
  }
}
