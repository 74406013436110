#kashtagInput {
    text-align: center;
}

header div.content ul{
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
}

header div.content ul{
    margin-right: 5%;
    margin-left: 5%;
}

header div.content div.content-box input.without-label#kashtagInput.error{
    border-bottom: 2px solid #F72244;
}