html {
  padding-bottom: 30px;
}
.body {
  padding-bottom: 30px;
}
header {
  background-image: -moz-linear-gradient(
    -140deg,
    rgb(10, 190, 156) 0%,
    rgb(12, 155, 188) 100%
  );
  background-image: -webkit-linear-gradient(
    -140deg,
    rgb(10, 190, 156) 0%,
    rgb(12, 155, 188) 100%
  );
  background-image: -ms-linear-gradient(
    -140deg,
    rgb(10, 190, 156) 0%,
    rgb(12, 155, 188) 100%
  );
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
header img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 280px;
  width: 120px;
  padding: 30px 0;
  /* padding: 0; */
}
header img.full {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  width: 480px;
}
header div.counterContainer {
  width: 100%;
  background: transparent;
  margin-top: -20px;
}
header div.counter {
  width: 280px;
  margin: 0 auto;
}
header div.counter p {
  line-height: 100%;
  font-family: m-r;
  font-size: 102%;
  font-weight: "bold";
  color: white;
}
header div.content {
  position: absolute;
  left: 50%;
  top: 190px;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}
header div.content div.content-box {
  background: #fff;
  padding: 65px 0px 10px 0px;
  width: 380px;
  margin-bottom: 50px;
  text-align: center;
  border-radius: 20px;
  box-shadow: -2.113px 4.532px 9px 0px rgba(13, 103, 119, 0.14);
  -webkit-box-shadow: -2.113px 4.532px 9px 0px rgba(13, 103, 119, 0.14);
  -moz-box-shadow: -2.113px 4.532px 9px 0px rgba(13, 103, 119, 0.14);
  -o-box-shadow: -2.113px 4.532px 9px 0px rgba(13, 103, 119, 0.14);
  -ms-box-shadow: -2.113px 4.532px 9px 0px rgba(13, 103, 119, 0.14);
}
header div.content div.content-box h1 {
  color: #828c8c;
  font-size: 20px;
  font-family: m-m;
  margin-bottom: 45px;
}
header div.content div.content-box input.without-label {
  border: none;
  border-bottom: 2px solid #e0e2e2;
  height: 40px;
  line-height: 20px;
  width: 240px;
  text-align: center;
  font-size: 22px;
  font-family: m-r;
  color: #828c8c;
  outline: none;
  margin-bottom: 25px;
}
header div.content div.content-box input.without-label::placeholder {
  color: #828c8c;
  opacity: 1;
}
header div.content div.content-box input.without-label:-ms-input-placeholder {
  color: #828c8c;
}
header div.content div.content-box input.without-label::-ms-input-placeholder {
  color: #828c8c;
}
header div.content ul {
  padding-left: 20px;
  margin-top: 30px;
}
header div.content ul li {
  text-align: center;
  color: #828c8c;
  font-size: 17px;
  font-family: m-r;
}
header div.content div.content-box div.results {
  margin-bottom: 53px;
  text-align: center;
}
header div.content div.content-box div.results span {
  color: #0abe9c;
  font-size: 22px;
  font-family: m-m;
  display: inline-block;
  margin-bottom: 49px;
  position: relative;
  margin: 0px 30px;
}
header div.content div.content-box span.phone-check {
  font-family: m-m;
  display: inline-block;
  margin-bottom: 45px;
  position: relative;
  margin: 0px 30px;
}
header div.content div.content-box span.phone-check h1 {
  margin-right: 5px;
}
header div.content div.content-box h4 {
  margin-bottom: 60px;
}
header div.content div.content-box div.results span button {
  position: absolute;
  right: -40px;
  text-align: center;
  font-size: 18px;
  bottom: -10px;
}
header div.content div.content-box span button {
  position: absolute;
  right: -40px;
  text-align: center;
  font-size: 18px;
  top: -10px;
}
header div.content div.content-box div.results span button i {
  color: #0abe9c;
}
header div.content div.content-box div.results span button i:hover {
  color: #828c8c;
}
header div.content div.content-box span button i {
  color: #828c8c;
}
header div.content div.content-box span button i:hover {
  color: #0abe9c;
}
header div.content div.content-box div.results span:last-child {
  margin-bottom: 0px;
}
header div.content div.content-box div.results span:after {
  content: "✓";
  margin-left: 15px;
}
header div.content div.content-box div.all-form {
  overflow: hidden;
}
header div.content div.content-box div.input {
  width: 300px;
  display: inline-block;
  border-bottom: 2px solid #e0e2e2;
}
header div.content div.content-box div.input label {
  width: 90px;
  text-align: left;
  float: left;
  height: 40px;
  line-height: 40px;
  font-family: m-m;
  font-size: 18px;
  color: #828c8c;
}
header div.content div.content-box div.input.floating label {
  width: 250px;
}
header div.content div.content-box div.input input {
  width: 275px;
  float: left;
  height: 40px;
  border: none;
  outline: none;
  font-family: m-r;
  color: #828c8c;
  font-size: 18px;
}
header div.content div.content-box div.input.phone input {
  width: 200px;
  float: left;
  height: 40px;
  border: none;
  outline: none;
  font-family: m-r;
  color: #828c8c;
  font-size: 22px;
}
header div.content div.content-box div.input.phone.phone-error {
  border-bottom: 2px solid #f72244;
}
header div.content div.content-box div.input.phone .select {
  width: 210px;
  float: left;
  height: 40px;
}
header div.content div.content-box div.input input::placeholder {
  color: #828c8c;
}
header div.content div.content-box div.input input::-ms-input-placeholder {
  color: #828c8c;
}
header div.content div.content-box div.input input:-ms-input-placeholder {
  color: #828c8c;
}
header div.content div.content-box div.input {
  margin-bottom: 35px;
}
header div.content div.content-box div.input:last-child {
  margin-bottom: 45px;
}
header div.content div.content-box a#Verificar,
a#Reservar,
input[type="submit"] {
  background: #0abe9c;
  color: #fff;
  font-size: 20px;
  font-family: m-m;
  border-radius: 50px;
  padding: 20px 50px;
  outline: none;
}
header div.content div.error {
  background: #e5a195;
  position: absolute;
  left: 0;
  bottom: -120px;
  z-index: -1;
  width: 100%;
  padding: 45px 0px 5px 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 90px;
}
header div.content div.error p {
  font-size: 16px;
  color: #fff;
  font-family: m-m;
}
header div.content div.content-box div.input.mobile-code {
  border: none;
  width: 163px;
}
header div.content div.content-box div.input.mobile-code input {
  border-bottom: 2px dashed #e0e2e2;
  width: 163px;
  font-size: 30px;
  letter-spacing: 10px;
}
header div.content div.content-box div.terms {
  overflow: hidden;
  width: 300px;
  margin: 0 auto;
}
header div.content div.content-box div.terms label {
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #828c8c;
  border-radius: 5px;
  position: relative;
  float: left;
}
header div.content div.content-box div.terms label:after {
  position: absolute;
  content: "✓";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: none;
  font-size: 16px;
  color: #828c8c;
}
header div.content div.content-box div.terms input:checked + label:after {
  display: block;
}
header div.content div.content-box div.terms input {
  display: none;
}
header div.content div.content-box div.terms span p {
  float: left;
  margin-left: 10px;
  font-size: 15px;
  color: #828c8c;
  font-family: m-r;
}
header div.content div.content-box div.terms span a {
  color: #828c8c;
  float: left;
  margin-left: 2%;
  font-family: m-sb;
  font-size: 15px;
}
header div.content div.content-box div.terms span a:hover {
  text-decoration: none;
  color: #0abe9c;
}
header div.content div.content-box span.counter {
  display: block;
  clear: both;
  margin: 0 auto;
  border: 2px solid #de280a;
  border-radius: 50px;
  width: 160px;
  height: 50px;
  line-height: 45px;
  font-size: 25px;
  color: #de280a;
  margin: 35px auto;
}
div.field {
  position: relative;
}
.loader {
  position: absolute;
  float: right;
  right: 35px;
  top: -15px;
}
div.field.submit .loader {
  top: 30px;
}
div.field.submit .submit.loader {
  right: 8%;
  top: 0px;
}
div.code-input {
  font-size: 30px;
  margin-bottom: 20px;
}
header div.content div.content-box h4.countryName {
  font-family: m-m;
  margin-top: -45px;
  font-size: 16px;
  color: #828c8c;
}
span.error {
  color: #f72244;
  display: block;
  text-align: center;
  font-size: 16px;
  margin: -25px 0px 50px 0px;
}
.modalTitle {
  font-family: m-m;
  margin: 0 auto;
  text-align: center;
  font-size: 115%;
}
.modalBody {
  font-family: m-m;
  text-align: left;
  font-size: 100%;
}
.modalButton.btn.btn-default {
  background: #0abe9c;
  color: #fff;
  font-size: 120%;
  font-family: m-m;
  border-radius: 50px;
  padding: 3% 0px 3% 0px;
  outline: none;
  width: 50%;
}
.modalButton.btn.btn-default.form {
  font-size: 140%;
  width: 30%;
}
div.modalFooter.modal-footer {
  text-align: center;
  display: block;
}
.termsModalBody {
  font-family: m-m;
  text-align: left;
  font-size: 130%;
}
div.title-question {
  width: 100%;
}
h1.app-title {
  display: inline-block;
}
button.question {
  font-size: 20px;
  margin-left: -100%;
  margin-right: 12%;
  margin-top: -3%;
  float: right;
}
button.question i {
  color: #828c8c;
}
button.question i:hover {
  color: #0abe9c;
}

/**
* qr page styles
*
*/
.content-qr {
  width: 96%;
  background: hsl(0deg 0% 89% / 44%);
  padding-bottom: 20px;
  border-radius: 12px;
}

.text-go {
  color: #505050;
  font-size: 22px;
  margin-top: 15px;
  font-family: m-sb;
}
.button-go {
  background-image: -webkit-linear-gradient(
    -140deg,
    rgb(10, 190, 156) 0%,
    rgb(12, 155, 188) 100%
  );
  color: #fff;
  width: 116px;
  padding: 8px;
  border: none;
  font-size: 20px;
  border-radius: 20px;
  display: inline-block;
  transition: 0.5s;
}
.button-go:hover {
  color: #fff;
  transform: scale(1.05);
}
.content-loading {
  text-align: center;
  justify-content: center;
  display: grid;
  margin-top: 35px;
}

/**
 * Tooltip Styles
 */
/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}
/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: -65%; /*5 -26*/
  margin-left: -40px;
  padding: 7px;
  width: 80px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 75%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  content: " ";
  font-size: 0;
  line-height: 0;
}
/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Typography */

.text-body {
  line-height: 24px;
  color: #2d3739;
}

.text-body.regular {
  font-weight: 400;
}

.text-body.medium {
  font-weight: 500;
}

.text-body.semibold {
  font-weight: 600;
}

.text-body.bold {
  font-weight: 700;
}

.text-body.x1 {
  font-size: 16px;
}

/* Spacing */
.gap-4 {
  gap: 24px;
}
