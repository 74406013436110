@import "../../../../styles/colors.scss";

.swiper-container{
  position: relative;
  bottom: 45px;

}

.swiper {
  .window {
    overflow: hidden;

    .slides {
      display: flex;
      flex-direction: row;
      transition: all 0.2s ease-out;
      position: relative;
      top: 15px;
      .slide {
        flex: 0 0 100%;
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: $gray-80;
      margin-right: 8px;
      margin-left: 8px;
      overflow: hidden;

      .fill {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: $primary-40;

        &.active {
          transform: translateX(0);
          animation-duration: 0.2s;
          animation-name: goInFillAnim;
        }
        &.inactive {
          transform: translateX(100%);
          animation-duration: 0.2s;
          animation-name: goOutFillAnim;
        }

        @keyframes goInFillAnim {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }

        @keyframes goOutFillAnim {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(100%);
          }
        }
      }
    }
  }
}

@media screen and ( max-height: 834px ){
 .swiper {
    .window {
      overflow-y: scroll;
      height: 75svh;

    }

    .last-slide{
      height: 80svh;
    }
  }
}