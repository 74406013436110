@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../styles/colors";

$font-family-base: "Poppins";

$btn-font-size: 14px;
$btn-font-weight: 600;
$btn-focus-width: 0;
$btn-padding-y: 12px;
$btn-padding-x: 24px;

$h2-font-size: 34px;

$theme-colors: (
  "primary": $blue-30,
);

.btn {
  width: 366px;
  max-width: 100%;

  &.btn-primary:active,
  &.btn-primary:focus {
    background-color: $blue-30;
    border-color: $blue-30;
  }

  &.btn-secondary {
    @include button-variant(#fff, #fff, #fff, #fff, #fff, #fff);
    color: $primary-30;

    &:hover,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $primary-20;
    }
  }
}

@import "../../node_modules/bootstrap/scss/bootstrap";
